import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Stay tuned" mdxType="Title" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://open.spotify.com/playlist/0U8Nh8ssGJjilNsh8qqj7V?si=NxPkRHnCR2OyHwhmFTvJQA"
        }}>{`Brazil 101 🍌🇧🇷`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://open.spotify.com/playlist/6DR8EaZ6UTdogSeWrsSTd6?si=ZP9m11RTR4qELVHRtbnX9Q"
        }}>{`Trilha do Bem ✌️`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://open.spotify.com/playlist/2v4FCqly43mcm66m6AaqcW?si=5dA14ACmRYCbHWHNSULfKw"
        }}>{`Get. Shit. Done. ☕️`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      